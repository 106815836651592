import { Routes } from '@angular/router';
import { NotFoundComponent } from '@/app/features/not-found/not-found-page/not-found.component';
import { LoginPageComponent } from '@/app/features/login/login-page/login-page.component';
import { DeniedPageComponent } from '@/app/features/denied/denied-page/denied-page.component';
import { AdminGuard } from '@/app/core/services/admin.guard';
import { appRoutes } from '@/app/app.model';
import { ProtectedRoutes, PublicRoutes } from '@/app/core/services/auth.guard';
import { tokenGuard } from '@/app/core/services/token.guard';

export const routes: Routes = [
  {
    path: appRoutes.ROOT,
    component: LoginPageComponent,
    canActivate: [PublicRoutes],
  },
  {
    path: appRoutes.NOT_FOUND,
    component: NotFoundComponent,
  },
  {
    path: appRoutes.DENIED,
    component: DeniedPageComponent,
  },
  {
    path: appRoutes.BARGE_LIST,
    loadComponent: () =>
      import(
        './features/barge-list/barge-list-page/barge-list-page.component'
      ).then((component) => component.BargeListPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.BARGE_DETAILS,
    loadComponent: () =>
      import(
        './features/barge-details/barge-details-page/barge-details-page.component'
      ).then((component) => component.BargeDetailsPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.REPORT_CENTER,
    loadComponent: () =>
      import(
        './features/reports/report-center-page/report-center-page.component'
      ).then((component) => component.ReportCenterPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.CONDITIONS,
    loadComponent: () =>
      import(
        './features/conditions/conditions-page/conditions-page.component'
      ).then((component) => component.ConditionsPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.BOAT_LIST,
    loadComponent: () =>
      import(
        './features/boat-list/boat-list-page/boat-list-page.component'
      ).then((component) => component.BoatListPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.BOAT_DETAILS,
    loadComponent: () =>
      import(
        './features/boat-details/boat-details-page/boat-details-page.component'
      ).then((component) => component.BoatDetailsPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.DASHBOARD,
    loadComponent: () =>
      import('./features/home/dashboard-page/dashboard-page.component').then(
        (component) => component.DashboardPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.MAP,
    loadComponent: () =>
      import('./features/map/map-page/map-page.component').then(
        (component) => component.MapPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SETTINGS,
    loadComponent: () =>
      import('./features/settings/settings-page/settings-page.component').then(
        (component) => component.SettingsPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SETTINGS_FILTERS,
    loadComponent: () =>
      import('./features/settings/filters-page/filters-page.component').then(
        (component) => component.FiltersPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SETTINGS_ODPAIRS,
    loadComponent: () =>
      import('./features/settings/odpairs-page/odpairs-page.component').then(
        (component) => component.OdpairsPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SETTINGS_EMAIL,
    loadComponent: () =>
      import('./features/settings/emails-page/emails-page.component').then(
        (component) => component.EmailsPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SETTINGS_FTP,
    loadComponent: () =>
      import('./features/settings/ftp-page/ftp-page.component').then(
        (component) => component.FtpPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SETTINGS_SCHEDULER,
    loadComponent: () =>
      import(
        './features/settings/scheduler-page/scheduler-page.component'
      ).then((component) => component.SchedulerPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SETTINGS_NOTIFICATIONS,
    loadComponent: () =>
      import(
        './features/settings/notifications-page/notifications-page.component'
      ).then((component) => component.NotificationsPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.EDIT_REPORT,
    loadComponent: () =>
      import(
        './features/reports/edit-report-page/edit-report-page.component'
      ).then((component) => component.EditReportPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.ADMIN_PAGE,
    loadComponent: () =>
      import('./features/admin/admin-page/admin-page.component').then(
        (component) => component.AdminPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes, AdminGuard],
  },
  {
    path: appRoutes.ADMIN_ANNOUNCEMENTS,
    loadComponent: () =>
      import(
        './features/admin/announcements-page/announcements-page.component'
      ).then((component) => component.AnnouncementsPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes, AdminGuard],
  },
  {
    path: appRoutes.USER_ADMIN,
    loadComponent: () =>
      import('./features/admin/user-admin-page/user-admin-page.component').then(
        (component) => component.UserAdminPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes, AdminGuard],
  },
  {
    path: appRoutes.ADMIN_PROFILE,
    loadComponent: () =>
      import(
        './features/admin/user-editor-page/user-editor-page.component'
      ).then((component) => component.UserEditorPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes, AdminGuard],
  },
  {
    path: appRoutes.OXY_BARGE_REPORTS,
    loadComponent: () =>
      import(
        './features/barge-report/barge-report-page/barge-report-page.component'
      ).then((component) => component.BargeReportPageComponent),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.MESSAGES,
    loadComponent: () =>
      import('./features/messages/messages-page/messages-page.component').then(
        (component) => component.MessagesPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.SEARCH,
    loadComponent: () =>
      import('./features/search/search-page/search-page.component').then(
        (component) => component.SearchPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.ERROR_PAGE,
    loadComponent: () =>
      import('./features/error/error-page/error-page.component').then(
        (component) => component.ErrorPageComponent,
      ),
    canActivate: [tokenGuard, ProtectedRoutes],
  },
  {
    path: appRoutes.WILDCARD_ROUTE,
    redirectTo: appRoutes.NOT_FOUND,
  },
];
