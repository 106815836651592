<div class="register-dialog-header">
  <h4 class="modal-title white-font">
    Request Account
    <button
      type="button"
      class="close white-font"
      style="
        font-weight: bold;
        outline: none;
        border: none;
        font-size: 30px;
        padding-top: 0;
        padding-bottom: 0;
      "
      aria-hidden="true"
      (click)="closeDialog()"
    >
      &times;
    </button>
  </h4>
</div>
<div>
  <form [formGroup]="registerForm">
    <div class="form-group has-feedback">
      <label> Company Name </label>
      <input
        type="text"
        placeholder="Company Name"
        formControlName="companyName"
      />
      @if (
        !registerForm.controls.companyName.untouched &&
        registerForm.controls.companyName.invalid
      ) {
        <div class="help-block error-color">
          {{
            getErrorMessage(registerForm.controls.companyName, "Company Name")
          }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> First Name </label>
      <input type="text" placeholder="First Name" formControlName="firstName" />
      @if (
        !registerForm.controls.firstName.untouched &&
        registerForm.controls.firstName.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.firstName, "First Name") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> Last Name </label>
      <input type="text" placeholder="Last Name" formControlName="lastName" />
      @if (
        !registerForm.controls.lastName.untouched &&
        registerForm.controls.lastName.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.lastName, "Last Name") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> Title </label>
      <input type="text" placeholder="Title" formControlName="title" />
      @if (
        !registerForm.controls.title.untouched &&
        registerForm.controls.title.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.title, "Title") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> Work Phone Number </label>
      <input type="text" placeholder="Work Phone" formControlName="workPhone" />
      @if (
        !registerForm.controls.workPhone.untouched &&
        registerForm.controls.workPhone.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.workPhone, "Work Phone") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> Email Address </label>
      <input type="text" placeholder="E-mail" formControlName="email" />
      @if (
        !registerForm.controls.email.untouched &&
        registerForm.controls.email.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.email, "E-mail") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> Address </label>
      <input type="text" placeholder="Address" formControlName="address" />
      @if (
        !registerForm.controls.address.untouched &&
        registerForm.controls.address.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.address, "Address") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> City </label>
      <input type="text" placeholder="City" formControlName="city" />
      @if (
        !registerForm.controls.city.untouched &&
        registerForm.controls.city.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.city, "City") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> State </label>
      <select formControlName="state">
        @for (state of states; track state.abbreviation) {
          <option [value]="state.abbreviation">{{ state.name }}</option>
        }
      </select>
      @if (
        !registerForm.controls.state.untouched &&
        registerForm.controls.state.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.state, "State") }}
        </div>
      }
    </div>
    <div class="form-group has-feedback">
      <label> Zip Code </label>
      <input type="text" placeholder="Zip Code" formControlName="zip" />
      @if (
        !registerForm.controls.zip.untouched &&
        registerForm.controls.zip.invalid
      ) {
        <div class="help-block error-color">
          {{ getErrorMessage(registerForm.controls.zip, "Zip Code") }}
        </div>
      }
    </div>
    @if (!this.isInternalUser) {
      <div class="form-group accountOptions has-feedback">
        <input type="radio" value="Customer" formControlName="optradio" />
        Customer
        <input type="radio" value="vendor" formControlName="optradio" />
        Vendor/Terminal
        @if (
          !registerForm.controls.optradio.untouched &&
          registerForm.controls.optradio.invalid
        ) {
          <div class="help-block error-color">
            {{ getErrorMessage(registerForm.controls.optradio, "User type") }}
          </div>
        }
      </div>
    }
    @if (!this.isInternalUser) {
      <div class="form-group has-feedback">
        <label>Comments</label>
        <textarea
          placeholder="What barge or area of the river are your requesting to see?"
          rows="5"
          formControlName="txtComment"
        ></textarea>
      </div>
    }
  </form>
</div>
<div class="register-dialog-footer">
  <button (click)="submitRegisterForm()" class="submit-button">
    {{ "Submit" | uppercase }}
  </button>
  <button (click)="closeDialog()">Cancel</button>
</div>
