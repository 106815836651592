<div class="sent-register-dialog-header">
  <h4 class="modal-title white-font" style="margin-right: 20px">
    Request Submitted
    <button
      type="button"
      class="close white-font"
      style="
        font-weight: bold;
        outline: none;
        border: none;
        font-size: 30px;
        padding-top: 0;
        padding-bottom: 0;
      "
      aria-hidden="true"
      (click)="closeDialog()"
    >
      &times;
    </button>
  </h4>
</div>
<div class="dialog-content">
  <p>
    An email has been sent to the email address you provided. Please check and
    follow the instructions to complete the request.
  </p>
  <p>
    If you have any questions, please email
    <a href="mailto:towlinecustomer.support@ingrambarge.com">
      Towline Customer Support
    </a>
    .
  </p>
  <p class="button-section">
    <button class="close-dialog-button" (click)="dialogRef.close()">
      Close
    </button>
  </p>
</div>
<div class="sent-register-dialog-footer"></div>
