import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { EMPTY } from 'rxjs';

import { routes } from './app.routes';
import { authInterceptor } from './core/interceptors/api.interceptor';
import { AuthService } from './core/services/auth.service';

export function initialize(authService: AuthService) {
  const token = authService.getToken();
  const username = authService.getUsername();
  const hasLogged = !!token && !!username;
  if (hasLogged) {
    authService.setToken(token);
    authService.nextUsername(username);
  }
  return () => hasLogged || EMPTY;
}

function tokenGetter(authService: AuthService) {
  return authService.getToken();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      multi: true,
      deps: [AuthService],
    },
    provideRouter(routes),
    provideAnimations(),
    {
      provide: Window,
      useValue: window,
    },
    importProvidersFrom(
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          deps: [AuthService],
          useValue: {
            tokenGetter,
          },
        },
      }),
    ),
  ],
};
