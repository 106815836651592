<body class="fullbg image-background">
  <div id="wrapper" class="page-container">
    <div class="bg bg1" style="padding: 0">
      <div class="container">
        <div
          style="
            padding: 100px 0;
            height: 60%;
            min-height: 225px;
            border: 1px solid none;
          "
        >
          <img
            src="assets/images/towlinelogo.png"
            class="img-responsive"
            style="margin: 0px auto"
            alt=""
          />
        </div>
        <div class="panel panel-default" style="padding: 20px; font-size: 150%">
          <h1>404 - Page Not Found</h1>
          <p style="font-size: inherit">
            It seems that you've been incorrectly directed here.
            <a href="{{ appRoutes.DASHBOARD }}">Click here</a> to return to your
            dashboard.
          </p>
        </div>
      </div>
    </div>
  </div>
</body>
