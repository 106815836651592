import { Component, Inject, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { SentRegisterDialogComponent } from '@/app/features/login/sent-register-dialog/sent-register-dialog.component';
import { RegistrationState } from '@/app/core/models/login.model';
import {
  lettersAndPunctuation,
  lettersNumbersPunctuation,
  unicodeLettersPunctuation,
  workPhone,
  zipCode,
} from '@/app/core/utils/Regex.util';
import { getErrorMessage } from '@/app/core/utils/Validations.util';
import { NewUserRegistration } from '@/app/core/models/user.model';
import { UserService } from '@/app/core/services/user.service';

@Component({
  selector: 'app-register-dialog',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './register-dialog.component.html',
  styleUrl: './register-dialog.component.css',
})
export class RegisterDialogComponent implements OnInit {
  public dialogRef = inject(DialogRef<unknown>);
  dialog: Dialog = inject(Dialog);
  userService = inject(UserService);
  isInternalUser = false;

  registerFormAux = {
    companyName: '',
    firstName: '',
    lastName: '',
    title: '',
    workPhone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    optradio: '',
    txtComment: '',
  };

  constructor(@Inject(DIALOG_DATA) public data: boolean = false) {
    this.isInternalUser = data;
    if (!this.isInternalUser) {
      this.registerForm?.controls.optradio.setValidators([Validators.required]);
    }
  }

  ngOnInit(): void {
    if (!this.isInternalUser) {
      this.dialogRef.closed.subscribe(() => {
        if (this.registerForm.invalid) {
          return;
        }

        this.dialog.open(SentRegisterDialogComponent, {
          disableClose: true, // by ESC key or click outside
          panelClass: 'sent-register-dialog',
        });
      });
    }
  }

  registerForm = new FormGroup({
    companyName: new FormControl(this.registerFormAux.companyName, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(lettersNumbersPunctuation),
    ]),
    firstName: new FormControl(this.registerFormAux.firstName, [
      Validators.required,
      Validators.maxLength(25),
      Validators.pattern(lettersAndPunctuation),
    ]),
    lastName: new FormControl(this.registerFormAux.lastName, [
      Validators.required,
      Validators.maxLength(25),
      Validators.pattern(lettersAndPunctuation),
    ]),
    title: new FormControl(this.registerFormAux.title, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(unicodeLettersPunctuation),
    ]),
    workPhone: new FormControl(this.registerFormAux.workPhone, [
      Validators.required,
      Validators.maxLength(55),
      Validators.pattern(workPhone),
    ]),
    email: new FormControl(this.registerFormAux.email, [
      Validators.required,
      Validators.maxLength(50),
      Validators.email,
    ]),
    address: new FormControl(this.registerFormAux.address, [
      Validators.required,
      Validators.maxLength(75),
      Validators.pattern(unicodeLettersPunctuation),
    ]),
    city: new FormControl(this.registerFormAux.city, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(unicodeLettersPunctuation),
    ]),
    state: new FormControl(this.registerFormAux.state, [Validators.required]),
    zip: new FormControl(this.registerFormAux.zip, [
      Validators.required,
      Validators.maxLength(15),
      Validators.pattern(zipCode),
    ]),
    optradio: new FormControl(this.registerFormAux.optradio),
    txtComment: new FormControl(this.registerFormAux.txtComment),
  });

  states: RegistrationState[] = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];

  getErrorMessage = getErrorMessage;

  closeDialog() {
    this.dialogRef.close();
  }

  submitRegisterForm() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }

    const {
      address,
      city,
      companyName,
      firstName,
      lastName,
      email,
      title,
      workPhone,
      state,
      zip,
      txtComment,
      optradio,
    } = this.registerForm.value;
    const fullName = `${firstName} ${lastName}`;
    const emailBody = `Company: ${companyName}\n\nName: ${fullName}\n\nTitle: ${title}\n\nWork Phone: ${workPhone}\n\nEmail: ${email}\n\nAddress: ${address}\n\nCity: ${city}\n\nState: ${state}\n\nZip: ${zip}\n\nType: ${optradio}\n\nComment: ${txtComment}\n\n`;

    // trim comments field
    if (this.registerForm.controls.txtComment.value) {
      this.registerForm.controls.txtComment.setValue(
        this.registerForm.controls.txtComment.value.trim(),
      );
    }

    const userRegistration: NewUserRegistration = {
      ADDRESS: address as string,
      CITY: city as string,
      COMPANY: companyName as string,
      CONTACT_FIRST_NAME: firstName as string,
      CONTACT_LAST_NAME: lastName as string,
      EMAIL: email as string,
      EMAIL_BODY: emailBody as string,
      REQUEST_STATUS: 'REQUESTED',
      TITLE: title as string,
      WORK_PHONE: workPhone as string,
      STATE: state as string,
      ZIP_CODE: zip as string,
      CREATED_BY: fullName,
      UPDATED_BY: fullName,
    };

    this.userService.sendUserRegistration(userRegistration).subscribe(() => {
      this.dialogRef.close(this.registerForm);
    });
  }
}
