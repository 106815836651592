import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { appRoutes } from '../../app.model';

const validateRouter = (router: Router, route: string, requireAuth: boolean) =>
  inject(AuthService).isAuthenticated$.pipe(
    map((isAuthenticated) =>
      isAuthenticated === requireAuth ? true : router.createUrlTree([route]),
    ),
  );

export const ProtectedRoutes: CanActivateFn = () => {
  const authService = inject(AuthService);
  return authService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      }
      authService.goToAuthLogin();
      return false;
    }),
  );
};

export const PublicRoutes = () => {
  const router = inject(Router);
  return validateRouter(router, appRoutes.DASHBOARD, false);
};
