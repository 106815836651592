import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { appRoutes } from '@/app/app.model';

export const tokenGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  // If authority send #error in the url, redirect to denied page
  if (state?.url?.includes('error')) {
    return router.createUrlTree([appRoutes.DENIED]);
  }
  // If we get an access token in the url, set it
  if (state?.url?.includes('access_token')) {
    return authService.createSessionFromHash();
  }
  return true;
};
