<body>
  <div class="bg bg1 p-0">
    <div class="container">
      <div class="container-page">
        <img
          ngSrc="assets/images/towlinelogo.png"
          class="img-responsive logo-image"
          alt="towlinelogo"
          height="178"
          width="305"
        />
      </div>
      <div class="panel panel-default wrapper">
        <h1>Access Denied</h1>
        <p class="font-size-24">
          Your account does not have access to Towline. You can request access
          or log out and try with a different account.
        </p>
        <div class="row">
          <button
            class="btn btn-lg btn-danger col-lg-2 col-lg-offset-4"
            type="button"
            (click)="logout()"
            title="Log out"
          >
            Log Out
          </button>
          <button
            class="btn btn-lg btn-danger col-lg-2 ml-10"
            type="button"
            data-toggle="modal"
            data-target="#requestModal"
            (click)="openRegister()"
          >
            Request Access
          </button>
        </div>
        <div>&nbsp;</div>
      </div>
    </div>
  </div>
</body>
