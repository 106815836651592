import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { AuthService } from '@/app/core/services/auth.service';
import { log } from '@/app/core/utils/FormattedConsole.util';
import { CommonModule } from '@angular/common';
import { accessToken, username } from '@/mocks/data/userData';
import { environment } from '@/environments/environment';
import { RegisterDialogComponent } from '@/app/features/login/register-dialog/register-dialog.component';
import { appRoutes } from '@/app/app.model';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './login-page.component.html',
  providers: [
    {
      provide: Window,
      useValue: window,
    },
  ],
})
export class LoginPageComponent implements OnInit {
  public readonly appRoutes = appRoutes;
  authService = inject(AuthService);
  dialog: Dialog = inject(Dialog);
  router = inject(Router);
  bodyStyle: { 'background-image'?: string } = {};

  ngOnInit() {
    const images: string[] = [
      '/assets/images/coveredbg.jpg',
      '/assets/images/nightlockbg.jpg',
    ];

    Object.assign(this.bodyStyle, {
      'background-image': `url('${images[Math.round((Math.random() * 100) % 1)]}')`,
    });
  }

  fakeLogin() {
    this.authService.saveToken(accessToken);
    this.authService.saveUsername(username);
    this.authService.nextUsername(username);
    this.authService.saveOriginUsername(username);
    this.router.navigate([appRoutes.DASHBOARD]);
  }

  login() {
    log('Creating a new request for a session token.');
    if (environment.mocks) {
      this.fakeLogin();
    } else {
      this.authService.goToAuthLogin();
    }
  }

  openRegisterDialog() {
    this.dialog.open(RegisterDialogComponent, {
      width: '100%',
      disableClose: true, // by ESC key or click outside,
      panelClass: 'register-dialog',
    });
  }
}
