<body id="home" class="fullbg" [ngStyle]="bodyStyle">
  <form
    role="form"
    id="frmTow"
    name="frmTow"
    action=""
    novalidate="novalidate"
    class="bv-form"
  >
    <div id="wrapper" class="page-container">
      <div id="content" class="container">
        <div class="towLogo">
          <div style="padding-top: 20px; height: 20%; min-height: 225px">
            <img
              src="/assets/images/towlinelogo.png"
              class="img-responsive"
              style="margin: 0px auto"
              alt="Ingram Towline"
            />
          </div>
          <div style="display: none" class="banner">
            <div class="banner-title"></div>
            <div class="banner-content">
              We are experiencing provider issues with our phone service. Please
              contact us by mobile phone or email until this issue is resolved.
              <div class="text-center">
                <a
                  class="btn btn-primary duty"
                  href="./web/dutyList.pdf"
                  target="_blank"
                  role="button"
                >
                  Contact List
                </a>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-lg btn-primary btn-red"
              style="font-size: 24px; letter-spacing: 2px"
              (click)="login()"
            >
              Log In
            </button>
          </div>
          <div style="height: 60px">
            <p
              style="
                padding-bottom: 0px;
                padding-top: 20px;
                font-size: 16px;
                letter-spacing: 2px;
              "
            >
              <a (click)="openRegisterDialog()"> -Request Account- </a>
            </p>
          </div>
          <div class="btn-group">
            <a
              class="btn navbar-btn white-hover"
              href="https://www.facebook.com/IngramBargeCompany"
            >
              <i class="fa fa-facebook"></i>
            </a>
            <a
              class="btn navbar-btn white-hover"
              href="https://www.linkedin.com/company/ingram-barge-company"
            >
              <i class="fa fa-linkedin"></i>
            </a>
            <a
              class="btn navbar-btn white-hover"
              href="https://instagram.com/ingrambargecompany"
            >
              <i class="fa fa-instagram"></i>
            </a>
            <a
              class="btn navbar-btn white-hover"
              href="https://www.youtube.com/channel/UCfdKxHFy3ktODRGDvhEiASQ"
            >
              <i class="fa fa-youtube-play"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </form>
</body>
