import { Component, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-sent-register-dialog',
  standalone: true,
  templateUrl: './sent-register-dialog.component.html',
  styleUrl: './sent-register-dialog.component.css',
})
export class SentRegisterDialogComponent {
  public dialogRef = inject(DialogRef<unknown>);

  closeDialog() {
    this.dialogRef.close();
  }
}
