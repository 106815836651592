import { Component, inject } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { AuthService } from '@/app/core/services/auth.service';
import { RegisterDialogComponent } from '@/app/features/login/register-dialog/register-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { lastValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from '@/app/core/services/user.service';
import { RequestUser } from '@/app/core/models/user.model';

@Component({
  selector: 'app-denied-page',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './denied-page.component.html',
  styleUrl: './denied-page.component.css',
})
export class DeniedPageComponent {
  private authService = inject(AuthService);
  private dialog = inject(Dialog);
  private userService = inject(UserService);

  openRegister() {
    const dialogRef = this.dialog.open<RequestUser>(RegisterDialogComponent, {
      width: '100%',
      panelClass: 'register-dialog',
      data: true,
    });

    return lastValueFrom(
      dialogRef.closed.pipe(
        switchMap(async (values) => {
          if (values) {
            let body = 'Company: ' + values.companyName;
            body += '\n\nName: ' + values.firstName;
            body += '\n\nLastName: ' + values.lastName;
            body += '\n\nTitle: ' + values.title;
            body += '\n\nWork Phone: ' + values.workPhone;
            body += '\n\nEmail: ' + values.email;
            body += '\n\nAddress: ' + values.address;
            body += '\n\nCity: ' + values.city;
            body += '\n\nState: ' + values.state;
            body += '\n\nZip: ' + values.zip;
            const data = {
              COMPANY: values.companyName,
              CONTACT_FIRST_NAME: values.firstName,
              CONTACT_LAST_NAME: values.lastName,
              TITLE: values.title,
              WORK_PHONE: values.workPhone,
              EMAIL: values.email,
              ADDRESS: values.address,
              CITY: values.city,
              STATE: values.state,
              ZIP_CODE: values.zip,
              EMAIL_BODY: body,
              CREATED_BY: `${values.firstName} ${values.lastName}`,
              UPDATED_BY: `${values.firstName} ${values.lastName}`,
              REQUEST_STATUS: 'REQUESTED',
            };
            this.userService.requestAccount(data).subscribe();
          }
        }),
      ),
    );
  }

  logout() {
    this.authService.destroyTokenAndRedirect();
  }
}
