import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@/app/core/services/auth.service';
import { appRoutes } from '@/app/app.model';

export const AdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const isAdmin = authService.getIsAdmin();
  return isAdmin ? true : router.createUrlTree([appRoutes.DASHBOARD]);
};
